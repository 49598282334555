import {
  fetchCustomerProfile,
  fetchCustomerPassword,
  fetchCustomerEmailPending,
  fetchCustomerEmailChange,
  fetchCustomerEmailResend,
  fetchCustomerEmailAbort,
  fetchCustomerSecondFactor,
  fetchCustomerToggleTwoFactor,
  fetchCustomerSMSVerification,
  fetchCountries,
  fetchVerifySecondFactor,
  fetchSocialAccounts,
  fetchLinkSocialAccount,
  fetchUnlinkSocialAccount,
} from '@/services/profile'
import { fetchCustomer } from '@/services/customer'
import { setCustomer } from '@/configs/axios/token'

const initialState = {
  isCustomerPasswordUpdated: false,
  customerPasswordErrors: {},
  customerEmailPending: {},
  customerEmailErrors: {},
  isCustomerProfileUpdated: false,
  customerProfileErrors: {},
  twoFactor: {},
  twoFactorStatus: null,
  socialAccounts: null,
  socialAccountError: null,
  backupCodes: [],
  twoFactorToggleError: [],
  countries: []
}

export default {
  state: initialState,
  getters: {
    isCustomerPasswordUpdated: (state) => state.isCustomerPasswordUpdated,
    customerEmailPending: (state) => state.customerEmailPending,
    customerEmailErrors: (state) => state.customerEmailErrors,
    customerPasswordErrors: (state) => state.customerPasswordErrors,
    isCustomerProfileUpdated: (state) => state.isCustomerProfileUpdated,
    customerProfileErrors: (state) => state.customerProfileErrors,
    twoFactor: (state) => state.twoFactor,
    twoFactorStatus: (state) => state.twoFactorStatus,
    socialAccounts: (state) => state.socialAccounts,
    backupCodes: (state) => state.backupCodes,
    twoFactorToggleError: (state) => state.twoFactorToggleError,
    socialAccountError: (state) => state.socialAccountError,
    countries: (state) => state.countries
  },
  mutations: {
    setInitialState(state) {
      state.isCustomerPasswordUpdated = false
      state.customerEmailPending = {}
      state.customerPasswordErrors = {}
      state.customerEmailErrors = {}
      state.isCustomerProfileUpdated = false
      state.customerProfileErrors = {}
      state.getTwoFactor = {}
    },
    setDefaultProfileIsUpdated(state) {
      state.isCustomerProfileUpdated = false
    },
    setCountries(state, payload) {
      state.countries = payload.data
    },
    setCustomerPasswordIsUpdated(state) {
      state.customerPasswordErrors = {}
      state.isCustomerPasswordUpdated = true
    },
    setCustomerPasswordErrors(state, payload) {
      state.isCustomerPasswordUpdated = false
      state.customerPasswordErrors = payload
    },
    setCustomerEmailPending(state, payload) {
      state.customerEmailPending = payload
    },
    setCustomerEmailErrors(state, payload) {
      state.customerEmailErrors = payload
    },
    setCustomerProfileIsUpdated(state) {
      state.customerProfileErrors = {}
      state.isCustomerProfileUpdated = true
    },
    setCustomerProfileErrors(state, payload) {
      state.isCustomerProfileUpdated = false
      state.customerProfileErrors = payload
    },
    setTwoFactor(state, payload) {
      state.twoFactor = payload['2fa_secret']
    },
    setTwoFactorStatusNull(state) {
      state.twoFactorStatus = null
    },
    setTwoFactorEnable(state, payload) {
      state.twoFactorStatus = 'enable'
      state.backupCodes = payload.backup_codes
    },
    setTwoFactorDisable(state) {
      state.twoFactorStatus = 'disable'
    },
    setTwoFactorToggleError(state, payload) {
      state.twoFactorToggleError = payload
    },
    setSecondFactorBackupCodesNull(state) {
      state.backupCodes = []
    },
    setSocialAccounts(state, payload) {
      state.socialAccounts = payload
    },
    setSocialAccountError(state, payload) {
      state.socialAccountError = payload
    }
  },
  actions: {
    async getCustomerProfile() {
      const response = await fetchCustomer()

      if (response && response.status === 200) {
        await setCustomer(JSON.stringify(response.data.customer))
      }
    },

    async updateCustomerProfile({ commit }, form) {
      await commit('setDefaultProfileIsUpdated')

      const response = await fetchCustomerProfile(form)

      if (response && response.status === 204) {
        await commit('setCustomerProfileIsUpdated')
      } else if (response) {
        await commit('setCustomerProfileErrors', response.data)
      }
    },

    async getCountries({ commit }) {
      const response = await fetchCountries()

      if (response && response.status === 200) {
        commit('setCountries', response.data)
      }
    },

    async updateCustomerPassword({ commit }, form) {
      const response = await fetchCustomerPassword(form)

      if (response && response.status === 204) {
        commit('setCustomerPasswordIsUpdated')
      } else if (response) {
        commit('setCustomerPasswordErrors', response.data)
      }
    },

    async getCustomerEmailPending({ commit }) {
      const response = await fetchCustomerEmailPending()

      if (response && response.status === 200) {
        commit('setCustomerEmailPending', response?.data)
      }
      else {
        commit('setCustomerEmailErrors', response?.data)
      }
    },

    async requestCustomerEmailChange({ commit }, form) {
      const response = await fetchCustomerEmailChange(form)

      if (response && response.status === 200) {
        commit('setCustomerEmailErrors', {})
      }
      else if (response) {
        commit('setCustomerEmailErrors', response?.data)
      }
    },

    async resendCustomerEmail({ commit }) {
      const response = await fetchCustomerEmailResend()

      if (response && response.status === 200) {
        commit('setCustomerEmailErrors', {})
      }
      else if (response) {
        commit('setCustomerEmailErrors', response?.data)
      }
    },

    async abortCustomerEmail({ commit }) {
      const response = await fetchCustomerEmailAbort()

      if (response && response.status === 200) {
        commit('setCustomerEmailErrors', {})
      }
      else if (response) {
        commit('setCustomerEmailErrors', response?.data)
      }
    },

    async getCustomerTwoFactor({ commit }) {
      const response = await fetchCustomerSecondFactor()

      if (response && response.status === 200) {
        commit('setTwoFactor', response.data)
      }
    },

    async enableTwoFactor({ commit }, data) {
      const response = await fetchCustomerToggleTwoFactor('enable', data)

      if (response && response.status === 200) {
        commit('setTwoFactorEnable', response.data)
      } else if (response) {
        commit('setTwoFactorToggleError', response.data)
      }
    },

    async disableTwoFactor({ commit }, data) {
      const response = await fetchCustomerToggleTwoFactor('disable', data)

      if (response && response.status === 200) {
        commit('setTwoFactorDisable')
      } else if (response) {
        commit('setTwoFactorToggleError', response.data)
      }
    },

    async verifyTwoFactor({ commit }, data) {
      const response = await fetchVerifySecondFactor(data)
      if (response && response.status !== 200) {
        commit('setTwoFactorToggleError', response.data)
      }
      return response
    },


    async getSocialAccounts({ commit }, data) {
      const response = await fetchSocialAccounts(data)
      if (response && response.status === 200) {
        commit('setSocialAccounts', response.data)
      } else if (response) {
        commit('setSocialAccountError', response.data)
      }
    },

    async toggleSocialAccount({ commit }, { providerName, toggle }) {
      const response = toggle ? await fetchLinkSocialAccount(providerName) : await fetchUnlinkSocialAccount(providerName);

      if (response && response.status === 200) {
        commit('setSocialAccounts', response.data);
      } else if (response) {
        commit('setSocialAccountError', response.data);
      }
    },

    async verifySMS({ commit }, data) {
      const response = await fetchCustomerSMSVerification(data)

      if (response && response.data.customer) {
        commit('setCustomer', response.data, { root: true })
      }

      return response
    }
  },
}
