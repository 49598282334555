import { fetchProfileCompletion } from '@/services/auth/registration'

const initialState = {
  profileCompletionSuccess: false,
  profileCompletionSuccessMessage: '',
  profileCompletionError: false,
  profileCompletionErrorMessage: []
}

export default {
  state: initialState,
  getters: {
    profileCompletionMessage: state => state.profileCompletionSuccessMessage,
    profileCompletionResponseMessage: state => state.profileCompletionErrorMessage,
    profileCompletionSuccess: state => state.profileCompletionSuccess,
    profileCompletionError: state => state.profileCompletionError
  },
  mutations: {
    setInitialState(state) {
      state.profileCompletionSuccess = false
      state.profileCompletionSuccessMessage = ''
      state.profileCompletionError = false
      state.profileCompletionErrorMessage = []
    },
    async setProfileCompletionSuccess(state, payload) {
      state.profileCompletionSuccess = true
      state.profileCompletionError = false
      state.profileCompletionSuccessMessage = payload.customer
    },
    setProfileCompletionError(state, payload) {
      state.profileCompletionSuccess = false
      state.profileCompletionSuccessMessage = ''
      state.profileCompletionError = true
      state.profileCompletionErrorMessage = payload
    }
  },
  actions: {
    async profileCompletion({ commit }, form) {
      await commit('setInitialState')

      const response = await fetchProfileCompletion(form)

      if (response && response.status === 200) {
        // Track the registered-account goal
        // window._paq.push(['trackGoal', import.meta.env.VITE_MATOMO_REGISTERED_GOAL]);

        await commit('setProfileCompletionSuccess', response.data)
      } else if (response && response.data.code === "profile.already-completed") {
        await commit('setProfileCompletionSuccess', response.data)
      } else if (response) {
        commit('setProfileCompletionError', response.data)
      }
    }
  }
}
