import Auth from '@/middlewares/auth'

export default [
  {
    name: 'Balance',
    path: '/payment/balance',
    component: () => import('@/pages/payment/balance/Balance'),
    meta: {
      title: 'VPS2Day - Balance',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Invoice',
    path: '/payment/invoice',
    component: () => import('@/pages/payment/invoice/Invoice'),
    meta: {
      title: 'VPS2Day - Invoice',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Transaction',
    path: '/payment/transaction',
    component: () => import('@/pages/payment/transaction/Transaction'),
    meta: {
      title: 'VPS2Day - Transaction',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Payment Intent Completed',
    path: '/payment/charge/:transactionId/completed',
    component: () => import('@/pages/payment/payment/PaymentComplete'),
    meta: {
      title: 'VPS2Day - Payment Intent Completed',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Payment Intent Cancelled',
    path: '/payment/charge/:transactionId/cancelled',
    component: () => import('@/pages/payment/payment/PaymentCancelled'),
    meta: {
      title: 'VPS2Day - Payment Intent Cancelled',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Affiliate',
    path: '/payment/affiliate',
    component: () => import('@/pages/payment/affiliate/Affiliate'),
    meta: {
      title: 'VPS2Day - Affiliate',
      middleware: Auth,
      auth: true
    }
  }
];
