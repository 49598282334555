import { getInstance } from '@/configs/axios'
import { CSRFRequest } from '@/services/auth/csrf'
import moment from 'moment-timezone'

export const fetchUserRegistration = async (form) => {
  await CSRFRequest()

  const timezone = moment.tz.guess()
  const payload = { ...form, timezone }

  return await getInstance().post('/api/customer/v1/auth/register', payload)
    .catch(function (error) {
      return error.response
    })
}

export const fetchProfileCompletion = async (form) => {
  const timezone = moment.tz.guess()
  const payload = { ...form, timezone }

  return await getInstance().post('/api/customer/v1/profile/complete', payload)
    .catch(function (error) {
      return error.response
    })
}
