import { getInstance } from '@/configs/axios'

export const fetchCustomer = async () => {
  return getInstance().get('/api/customer/v1/customer')
    .catch(function (error) {
      return error.response
    })
}

export const fetchSetCustomerLocale = async (locale) => {
  return getInstance().patch('/api/customer/v1/profile/locale', { locale })
    .catch(function (error) {
      return error.response
    })
}

export const fetchCustomerMigrate = async (data) => {
  return getInstance().post('/api/customer/v1/profile/migrate', data)
    .catch(function (error) {
      return error.response
    })
}