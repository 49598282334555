import { fetchCustomer, fetchSetCustomerLocale, fetchCustomerMigrate } from '@/services/customer'
import { setCustomer, getCustomer } from '@/configs/axios/token'
import { useCookies } from 'vue3-cookies'
import i18n from '@/i18n'

const { cookies } = useCookies()

const initialState = {
  customer: [],
  isCustomerRegistrationCompleted: true,
  isMigrationSuccess: null,
  isMigrationSuccessMessage: '',
  migrationErrors: {}
}

export default {
  state: initialState,
  getters: {
    customer: (state) => {
      let customer = state.customer

      if (!customer) {
        customer = getCustomer
      }

      return customer
    },
    isCustomerRegistrationCompleted: (state) => state.isCustomerRegistrationCompleted,
    isMigrationSuccess: (state) => state.isMigrationSuccess,
    isMigrationSuccessMessage: (state) => state.isMigrationSuccessMessage,
    migrationErrors: (state) => state.migrationErrors,
  },
  mutations: {
    setInitialState(state) {
      state.customer = []
    },
    setInitialStateMigration(state) {
      state.isMigrationSuccess = false
      state.isMigrationSuccessMessage = ''
      state.migrationErrors = {}
    },
    setCustomer(state, payload) {
      state.customer = payload.customer
    },
    setIsCustomerRegistrationCompleted(state, payload) {
      state.isCustomerRegistrationCompleted = payload
    },
    setMigrationSuccess(state, payload) {
      state.migrationErrors = {}
      state.isMigrationSuccess = true
      state.isMigrationSuccessMessage = payload.message
    },
    setMigrationErrors(state, payload) {
      state.migrationSuccess = false
      state.isMigrationSuccessMessage = ''
      state.migrationErrors = payload
    }
  },
  actions: {
    async getCustomer({commit}) {
      await commit('setInitialState')

      const response = await fetchCustomer()

      if (response && response.status === 200) {
        await commit('setCustomer', response.data)
        await commit('setIsCustomerRegistrationCompleted', response.data.customer.registration_completed_at)
        await setCustomer(JSON.stringify(response.data.customer))

        if(response.data.customer.locale) {
          cookies.set('locale', response.data.customer.locale)
          i18n.global.locale.value = response.data.customer.locale
        }

      }
    },
    async setCustomerLocale({}, locale) {
      await fetchSetCustomerLocale(locale).then(() => {
        cookies.set('locale', locale)
      })
    },

    async customerMigrate({ commit }, data) {
      const response = await fetchCustomerMigrate(data)
      commit('setInitialStateMigration')

      if (response && response.status === 200) {
        commit('setMigrationSuccess', response.data)
      } else if (response) {
        commit('setMigrationErrors', response.data)
      } else {
        commit('setInitialStateMigration')
      }
    },
  },
}
