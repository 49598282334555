import {
  fetchServerChangeIp,
  fetchServerIpPrice,
} from '@/services/service/server'

const initialState = {
  isChanged: false,
  changeErrors: [],
  ipPricing: {},
  ipPricingError: [],
}

export default {
  state: initialState,
  getters: {
    isIpChanged: (state) => state.isChanged,
    ipChangeErrors: (state) => state.changeErrors,
    ipPricing: (state) => state.ipPricing,
    ipPricingError: (state) => state.ipPricingError,
  },
  mutations: {
    setInitialState(state) {
      state.isChanged = false
      state.changeErrors = []
      state.ipPricingError = []
    },
    setChangedIp(state) {
      state.isChanged = true
    },
    setChangedIpError(state, payload) {
      state.changeErrors = payload
    },
    setIpPricing(state, payload) {
      // payload: { "changeable": true, "reason": null, "ip_address": "X.X", "price": 250 }
      state.ipPricing = payload
    },
    setIpPricingError(state, payload) {
      // payload: { "changeable": false, "reason": "reason.ip-changed-too-often", "ip_address": "X.X", "price": null }
      state.ipPricingError = payload
    }
  },
  actions: {
    async changeIp({commit}, data) {
      commit('setLocked')
      commit('setInitialState')

      const response = await fetchServerChangeIp(data)

      if (response && response.status === 200) {
        commit('setChangedIp')
      } else {
        commit('setChangedIpError', response.data)
      }
    },
    async fetchIpPrice({commit}, data) {
      const response = await fetchServerIpPrice(data)

      if (response && response.status === 200) {
        commit('setIpPricing', response.data)
      } else if (response) {
        commit('setIpPricingError', response.data)
      }
    },
  },
}
