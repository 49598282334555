import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

export default async ({ next, to }) => {
  if (to.params.referral) {
    const activeReferral = cookies.isKey('vps2day_referral')

    if(!activeReferral) {
      cookies.set('vps2day_referral', to.params.referral, '48h')
    }

    return next('/register')
  }
  return next()
}