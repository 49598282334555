import { fetchPasswordReset } from '@/services/auth/reset-password'

const initialState = {
  resetPasswordSuccess: false,
  resetPasswordError: false,
  resetPasswordMessage: []
}

export default {
  state: initialState,
  getters: {
    resetPasswordSuccess: state => state.resetPasswordSuccess,
    resetPasswordMessage: state => state.resetPasswordMessage,
    resetPasswordError: state => state.resetPasswordError
  },
  mutations:{
    setInitialState(state) {
      state.resetPasswordError = false
      state.resetPasswordSuccess = false
      state.resetPasswordMessage = []
    },
    setSuccessReset(state, payload) {
      state.resetPasswordSuccess = true
      state.resetPasswordError = false
      state.resetPasswordMessage = payload
    },
    setError(state, payload) {
      state.resetPasswordError = true
      state.resetPasswordMessage = payload
    }
  },
  actions: {
    async resetData({ commit }) {
      commit('setInitialState')
    },

    async resetPassword({ commit }, form) {
      commit('setInitialState')

      const response = await fetchPasswordReset(form)

      if (response && response.status === 200) {
        commit('setSuccessReset', response.data)
      } else if (response) {
        commit('setError', response.data)
      }
    }
  }
}
