import { fetchServerMetrics } from '@/services/service/server'

const initialState = {
  metrics: [],
}

export default {
  state: initialState,

  getters: {
    metrics: (state) => state.metrics
  },

  mutations: {
    setMetrics(state, payload) {
      state.metrics = payload
    },
  },

  actions: {
    async getServerMetrics({commit}, serverId) {
      const response = await fetchServerMetrics(serverId)

      if (response && response.status === 200) {
        commit('setMetrics', response.data)
      }
    }
  },
}
