import { fetchRefreshToken, fetchUserLogin, fetchUserLogOut } from '@/services/auth/login'
import { setCustomer, setAuthorizedUserRefreshTokenSuccess, unsetCustomer } from '@/configs/axios/token'
import { useCookies } from 'vue3-cookies'

const initialState = {
  userLoginSuccess: false,
  userLoginError: false,
  userLoginErrorMessage: []
}

const { cookies } = useCookies()

export default {
  state: initialState,
  getters: {
    userLoginSuccess: state => state.userLoginSuccess,
    loginResponseMessage: state => state.userLoginErrorMessage,
    userLoginError: state => state.userLoginError
  },
  mutations:{
    setInitialState(state) {
      state.userLoginSuccess = false
      state.userLoginError = false
      state.userLoginErrorMessage = []
    },
    async setUserLoginSuccess(state, payload) {
      state.userLoginSuccess = true
      state.userLoginError = false
      // await setAuthorizedUserCredentials(token, tokenExpiredDate, remember)
    },
    setUserLoginError(state, payload) {
      state.userLoginSuccess = false
      state.userLoginError = true
      state.userLoginErrorMessage = payload
    },
    async setUserRefreshTokenSuccess(state, { tokenExpiredDate, token, remember }) {
      await setAuthorizedUserRefreshTokenSuccess(token, tokenExpiredDate, remember)
    }
  },
  actions: {
    async userLogin({ commit, dispatch }, form) {
      await commit('setInitialState')

      const response = await fetchUserLogin(form)

      if (response && response.status === 200) {
        await setCustomer(JSON.stringify(response.data.customer))
        await commit('setUserLoginSuccess', response.data)

        if(cookies.get('locale')) {
          await dispatch('setCustomerLocale', cookies.get('locale'))
        }

      } else if (response) {
        commit('setUserLoginError', response.data)
      }
    },
    async userLogOut() {
      await fetchUserLogOut().then(() => {
        unsetCustomer()
      })
    },
    async refreshToken({ commit }) {
      const response = await fetchRefreshToken()

      if (response && response.status === 200) {
        await commit('setUserRefreshTokenSuccess', response.data.data)
      } else if (response) {
        commit('setUserRefreshTokenError', response.data)
      }
    }
  }
}
