import { getInstance } from '@/configs/axios'

export const fetchAnnouncements  = async () => {
  return getInstance().get('/api/customer/v1/announcement')
    .catch(function (error) {
      return error.response
    })
}

export const fetchAnnouncement  = async (id) => {
  return getInstance().get(`/api/customer/v1/announcement/${id}`)
    .catch(function (error) {
      return error.response
    })
}