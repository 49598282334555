import login from './auth/login.json'
import register from './auth/register.json'
import profileCompletion from './auth/profileCompletion.json'
import passwordRequest from './auth/passwordRequest.json'
import passwordReset from './auth/passwordReset.json'
import twoFaAuth from './auth/2faAuth.json'
import migrate from './auth/migrate.json'
import customerNavigation from './customer/navigation.json'
import announcements from './customer/announcements.json'
import abuseManagement from './customer/abuseManagement.json'
import dashboard from './customer/dashboard.json'
import profile from './customer/profile.json'
import support from './customer/support.json'
import balance from './payment/balance.json'
import invoice from './payment/invoice.json'
import payment from './payment/payment.json'
import sshKey from './service/sshKey.json'
import upgrade from './service/upgrade.json'
import server from './service/server.json'
import disk from './service/disk.json'
import ip from './service/ip.json'
import chart from './service/chart.json'
import overview from './service/overview.json'
import privateNetwork from './service/privateNetwork.json'
import transaction from './payment/transaction.json'
import headers from './headers.json'
import countries from './countries.json'
import validation from './validation.json'
import global from './global.json'
import commonNavigation from './common/navigation.json'
import commonGlobal from './common/global.json'
import tickets from './support/tickets.json'
import abuse from './support/abuse.json'
import snapshot from './service/snapshot.json'
import backup from './service/backup.json'
import network from './service/network.json'
import affiliate from './payment/affiliate.json'

export default {
    login,
    register,
    profileCompletion,
    passwordRequest,
    passwordReset,
    twoFaAuth,
    migrate,
    customerNavigation,
    announcements,
    abuseManagement,
    dashboard,
    profile,
    support,
    balance,
    invoice,
    payment,
    sshKey,
    upgrade,
    headers,
    countries,
    validation,
    global,
    commonNavigation,
    commonGlobal,
    transaction,
    tickets,
    server,
    disk,
    ip,
    chart,
    overview,
    privateNetwork,
    abuse,
    snapshot,
    backup,
    network,
    affiliate,
}
