import { sendMessage, sendFile } from '@/services/support/tickets/messages'

const initialState = {
  msg: null,
  msgSuccess: false,
  msgError: false,
  msgErrorMessage: {},
  fileSuccess: false,
  fileError: false,
  fileErrorMessage: {},
}

export default {
  state: initialState,
  getters: {
    getMessage: (state) => state.msg,
    getMessageErrors: (state) => state.msgErrorMessage
  },
  mutations: {
    setInitialState(state) {
      state.msg = null
      state.msgSuccess = false
      state.msgError = false
      state.msgErrorMessage = []
    },
    async setMessagesSuccess(state, payload) {
      state.msgSuccess = true
      state.msg = payload.message
    },
    setMessagesError(state, payload) {
      state.msgError = true
      state.msgErrorMessage = payload
    },

    async setFileSuccess(state, payload) {
      state.fileSuccess = true
    },
    setFileError(state, payload) {
      state.fileError = true
      state.fileErrorMessage = payload
    },
  },
  actions: {
    async sendMessage({commit}, data) {
      const response = await sendMessage(data.form, data.ticketId)

      if (response && response.status === 201) {
        await commit('setMessagesSuccess', response.data)
      } else if(response.status === 429) {
        const error = {
          errors: {
            message: [],
            codeManyRequest: 'tickets.messageCreationRateLimit'
          }
        }

        commit('setMessagesError', error)
      } else if(response) {
        commit('setMessagesError', response.data)
      }
    },

    async sendFile({ commit }, data) {
      const promise = []

      data.files.forEach((item) => {
        const formData = new FormData()
        formData.append('attachment', item)

        promise.push(sendFile(formData, data.ticketId))
      })

      const failed = await Promise.all(promise).then(data => {
        const failedUploadsIndex = []

        data.forEach((item, idx) => {
          if (item.status !== 204) {
            failedUploadsIndex.push(idx)
          }
        })

        return failedUploadsIndex
      })

      return {
        failed
      }
    }
  },
}
