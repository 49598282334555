import Auth from '@/middlewares/auth'

export default [
  {
    name: 'Server',
    path: '/service/server',
    component: () => import('@/pages/service/server/Server'),
    meta: {
      title: 'VPS2Day - Server',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Delete',
    path: '/service/server/:id/delete',
    component: () => import('@/pages/service/server/show/Delete'),
    meta: {
      title: 'VPS2Day - Delete Server',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Disks',
    path: '/service/server/:id/disks',
    component: () => import('@/pages/service/server/show/Disks'),
    meta: {
      title: 'VPS2Day - Server Disks',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Network',
    path: '/service/server/:id/network',
    component: () => import('@/pages/service/server/show/Network'),
    meta: {
      title: 'VPS2Day - Server Network',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Overview',
    path: '/service/server/:id/overview',
    component: () => import('@/pages/service/server/show/Overview'),
    meta: {
      title: 'VPS2Day - Server Overview',
      middleware: Auth,
      auth: true
    }
  },
  {
    path: '/service/server/:id',
    redirect: {
      name: 'Overview'
    },
    meta: {
      title: 'VPS2Day - Server Overview',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Application',
    path: '/service/server/:id/application',
    component: () => import('@/pages/service/server/show/Application'),
    meta: {
      title: 'VPS2Day - Server Application',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Snapshots',
    path: '/service/server/:id/snapshots',
    component: () => import('@/pages/service/server/show/Snapshots'),
    meta: {
      title: 'VPS2Day - Server Snapshots',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Backup',
    path: '/service/server/:id/backup',
    component: () => import('@/pages/service/server/show/Backup'),
    meta: {
      title: 'VPS2Day - Server Backup',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Upgrades',
    path: '/service/server/:id/upgrades',
    component: () => import('@/pages/service/server/show/Upgrade'),
    meta: {
      title: 'VPS2Day - Server Upgrades',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Reinstall',
    path: '/service/server/:id/reinstall',
    component: () => import('@/pages/service/server/show/ReInstall'),
    meta: {
      title: 'VPS2Day - Reinstall Server',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'VNC',
    path: '/service/server/:id/vnc',
    component: () => import('@/pages/service/server/show/Vnc'),
    meta: {
      title: 'VPS2Day - Server VNC',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Statistics',
    path: '/service/server/:id/statistics',
    component: () => import('@/pages/service/server/show/Statistics'),
    meta: {
      title: 'VPS2Day - Server Statistics',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Settings',
    path: '/service/server/:id/settings',
    component: () => import('@/pages/service/server/show/Settings'),
    meta: {
      title: 'VPS2Day - Server Settings',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Create',
    path: '/service/server/create',
    component: () => import('@/pages/service/server/CreateServer'),
    meta: {
      title: 'VPS2Day - Create Server',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'SshKey',
    path: '/service/ssh-key',
    component: () => import('@/pages/service/ssh-key/SshKey'),
    meta: {
      title: 'VPS2Day - SSH Key',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'SshKeyCreate',
    path: '/service/ssh-key/create',
    component: () => import('@/pages/service/ssh-key/Create'),
    meta: {
      title: 'VPS2Day - Create SSH Key',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'ImportGithub',
    path: '/service/ssh-key/import-github',
    component: () => import('@/pages/service/ssh-key/ImportGithub'),
    meta: {
      title: 'VPS2Day - Import Github',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'ImportGithub',
    path: '/service/reinstall',
    component: () => import('@/pages/service/server/show/ReInstall'),
    meta: {
      title: 'VPS2Day - ReInstall',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Private Network',
    path: '/service/private-network',
    component: () => import('@/pages/service/private-network/PrivateNetwork'),
    meta: {
      title: 'VPS2Day - Server Private Network',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Create Private Network',
    path: '/service/private-network/create',
    component: () => import('@/pages/service/private-network/CreatePrivateNetwork'),
    meta: {
      title: 'VPS2Day - Create Private Network',
      middleware: Auth,
      auth: true
    }
  },
];
