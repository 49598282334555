import { getInstance } from '@/configs/axios'

export const fetchCustomerProfile = async (form) => {
  return getInstance().patch('/api/customer/v1/profile', { ...form })
    .catch(function (error) {
      return error.response
    })
}

export const fetchCountries = async (form) => {
  return getInstance().get('/api/customer/v1/profile/country')
    .catch(function (error) {
      return error.response
    })
}

export const fetchCustomerPassword = async (form) => {
  return getInstance().patch('/api/customer/v1/profile/password', { ...form })
    .catch(function (error) {
      return error.response
    })
}

export const fetchCustomerEmailPending = async () => {
  return getInstance().get('/api/customer/v1/profile/email-change')
    .catch(function (error) {
      return error.response
    })
}

export const fetchCustomerEmailChange = async (form) => {
  return getInstance().post('/api/customer/v1/profile/email-change', { ...form })
    .catch(function (error) {
      return error.response
    })
}

export const fetchCustomerEmailAbort = async (form) => {
  return getInstance().delete('/api/customer/v1/profile/email-change')
    .catch(function (error) {
      return error.response
    })
}

export const fetchCustomerEmailResend = async () => {
  return getInstance().post('/api/customer/v1/profile/email-change/resend')
    .catch(function (error) {
      return error.response
    })
}

export const fetchCustomerSecondFactor = async () => {
  return getInstance().get('/api/customer/v2/profile/second-factor/secret')
    .catch(function (error) {
      return error.response
    })
}

export const fetchCustomerToggleTwoFactor = async (type, form) => {
  if (form) {
    return getInstance().post(`/api/customer/v2/profile/second-factor/${type}`, { ...form })
    .catch(function (error) {
      return error.response
    })
  } else {
    return getInstance().post(`/api/customer/v2/profile/second-factor/${type}`)
    .catch(function (error) {
      return error.response
    })
  }
}

export const fetchVerifySecondFactor = async (form) => {
    return getInstance().post('/api/customer/v2/auth/second-factor/verify', { ...form }).catch(function (error) {
      return error.response
    })
}


export const fetchSocialAccounts = async () => {
  return getInstance().get(`/api/customer/v1/profile/social-auth`)
    .catch(function (error) {
      return error.response
    })
}

export const fetchLinkSocialAccount = async (provider) => {
  provider = provider.toLowerCase();
  return getInstance().get(`/api/customer/v1/profile/social-auth/${provider}/redirect`)
    .catch(function (error) {
      return error.response
    })
}

export const fetchUnlinkSocialAccount = async (provider) => {
  provider = provider.toLowerCase();
  return getInstance().delete(`/api/customer/v1/profile/social-auth/${provider}`)
    .catch(function (error) {
      return error.response
    })
}

export const fetchCustomerSMSVerification = async (data) => {
  const api = data.phone? 'resend' : 'verify'
  return getInstance().post(`/api/customer/v1/auth/phone/${api}`, data)
  .catch(function (error) {
    return error.response
  })
}
