import { fetchAnnouncements, fetchAnnouncement } from '@/services/announcement'

const initialState = {
  announcements: [],
  timestamp: null
}

export default {
  state: initialState,
  getters: {
    announcements: (state) => state.announcements,
    announcementTimestamp: (state) => state.timestamp,
    hasNewAnnouncements: (state) => {
      if (state.announcements) {
        return state.announcements.find(item => item.read_at === null)
      }
    }
  },
  mutations: {
    setAnnouncements(state, payload) {
      state.announcements = payload
    },
    setTimestamp(state) {
      state.timestamp = new Date()
    }
  },
  actions: {
    async getAnnouncements({commit}) {
      const response = await fetchAnnouncements()

      if (response && response.status === 200) {
        commit('setAnnouncements', response.data.data)
        commit('setTimestamp')
      }
    },

    async getAnnouncement({commit}, id) {
      const response = await fetchAnnouncement(id)

      if (response && response.status === 200) {
        return response
      }
    }
  },
}
