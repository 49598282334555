import Auth from '@/middlewares/auth'

export default [
  {
    name: 'Profile Data',
    path: '/profile/edit/data',
    component: () => import('@/pages/profile/PersonalData'),
    meta: {
      title: 'VPS2Day - Profile Data',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Second Factor',
    path: '/profile/edit/second-factor',
    component: () => import('@/pages/profile/SecondFactor'),
    meta: {
      title: 'VPS2Day - Second Factor',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Linked Accounts',
    path: '/profile/edit/linked-accounts',
    component: () => import('@/pages/profile/SocialAccount'),
    meta: {
      title: 'VPS2Day - Linked Accounts',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Security',
    path: '/profile/edit/security',
    component: () => import('@/pages/profile/Security'),
    meta: {
      title: 'VPS2Day - Security',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Email',
    path: '/profile/edit/email',
    component: () => import('@/pages/profile/Email'),
    meta: {
      title: 'VPS2Day - Email',
      middleware: Auth,
      auth: true

    }
  },
  {
    name: 'Verification',
    path: '/profile/edit/verification',
    component: () => import('@/pages/profile/Verification'),
    meta: {
      title: 'VPS2Day - Verification',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'SMS Verification',
    path: '/profile/edit/sms-verification',
    component: () => import('@/pages/profile/SMSVerification'),
    meta: {
      title: 'VPS2Day - SMS Verification',
      middleware: Auth,
      auth: true
    }
  }
];
