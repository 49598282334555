
const initialState = {
  cancelTokens: []
}

export default {
  state: initialState,
  getters: {
    cancelTokens(state) {
      return state.cancelTokens
    }
  },
  mutations: {
    add_cancel_token(state, token) {
      state.cancelTokens.push(token)
    },
    clear_cancel_tokens(state) {
      state.cancelTokens = []
    }
  },
  actions: {
    cancel_pending_requests({ state, commit }) {
      state.cancelTokens.forEach((request, i) => {
        if(request.cancel){
          request.cancel()
        }
      })

      commit('clear_cancel_tokens')
    }
  },
}
