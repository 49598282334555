import { setCustomer, getCustomer, unsetCustomer } from '@/configs/axios/token'
import { fetchCustomer } from '@/services/customer'

export default async ({ next }) => {
  const customer = getCustomer()

  if (customer && customer['2fa_enabled_at'] !== null && customer['2fa_session_verified'] === false) {
    return next('/2fa-verify')
  }

  if (!customer) {
    const response = await fetchCustomer()

    if (response.status === 200) {
      await setCustomer(JSON.stringify(response.data.customer))
      return next()
    }

    unsetCustomer()
    return next('/login')
  }

  if (!customer.email_verified_at) {
    return next('/verify')
  }

  if (customer && customer.migration_completed_at === null && customer.legacy_account === true) {
    return next('/migrate')
  }

  return next()
}
