import Auth from '@/middlewares/auth'

export default [
  {
    name: 'Support Tickets',
    path: '/support/ticket',
    component: () => import('@/pages/support/ticket/Ticket'),
    meta: {
      title: 'VPS2Day - Support Tickets',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Create Ticket',
    path: '/support/ticket/create',
    component: () => import('@/pages/support/ticket/CreateTicket'),
    meta: {
      title: 'VPS2Day - Create Ticket',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Show Tickets',
    path: '/support/ticket/:ticketId',
    component: () => import('@/pages/support/ticket/ShowTicket'),
    meta: {
      title: 'VPS2Day - Support Ticket ',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Abuse Management',
    path: '/support/abuse',
    component: () => import('@/pages/support/abuse/Abuse'),
    meta: {
      title: 'VPS2Day - Abuse',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'Create Abuse',
    path: '/support/abuse/create/:abuseId',
    component: () => import('@/pages/support/abuse/CreateAbuse'),
    meta: {
      title: 'VPS2Day - Create Abuse',
      middleware: Auth,
      auth: true
    }
  }
];
