import { createStore } from 'vuex'
import customer from '@/store/modules/customer'
import login from '@/store/modules/auth/login'
import registration from '@/store/modules/auth/registration'
import profilCompletion from '@/store/modules/auth/profile-completion'
import forgotPassword from '@/store/modules/auth/forgot-password'
import resetPassword from '@/store/modules/auth/reset-password'
import tickets from '@/store/modules/support/tickets'
import ticket from '@/store/modules/support/tickets/ticket'
import messages from '@/store/modules/support/tickets/messages'
import abuse from '@/store/modules/support/abuse'
import balance from '@/store/modules/payment/balance'
import {
  server,
  disks,
  ip,
  networks,
  settings,
  snapshots,
  backups,
  upgrades,
  vnc,
  reinstall,
  statistics
} from '@/store/modules/service/server'
import sshKey from '@/store/modules/service/ssh-key'
import payment from '@/store/modules/payment'
import transaction from '@/store/modules/payment/transaction'
import invoice from '@/store/modules/payment/invoice'
import affiliates from '@/store/modules/payment/affiliate'
import profile from '@/store/modules/profile'
import pendingRequests from '@/store/modules/pending_requests'
import announcement from '@/store/modules/announcement'

export default createStore({
  modules: {
    customer,
    login,
    registration,
    profilCompletion,
    forgotPassword,
    resetPassword,
    tickets,
    ticket,
    messages,
    abuse,
    balance,
    server,
    disks,
    ip,
    networks,
    settings,
    snapshots,
    backups,
    upgrades,
    vnc,
    reinstall,
    statistics,
    sshKey,
    payment,
    transaction,
    invoice,
    affiliates,
    profile,
    pendingRequests,
    announcement
  },
})
