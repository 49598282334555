import deVPS2DAY from './de/vps2day';
import deCLOUD2DAY from './de/cloud2day';
import enVPS2DAY from './en/vps2day';
import enCLOUD2DAY from './en/cloud2day';

const label = import.meta.env.VITE_LABEL;

export default {
  de: label === 'VPS2DAY' ? deVPS2DAY : deCLOUD2DAY,
  en: label === 'VPS2DAY' ? enVPS2DAY : enCLOUD2DAY
};
