import { fetchServerCreateBackup, fetchServerBackupRestore, fetchServerBackupDelete } from '@/services/service/server'

const initialState = {
  isServerBackupIsCreated: false,
  isRestoredBackup: false,
  restoreBackupErrors: []
}

export default {
  state: initialState,
  getters: {
    isServerBackupIsCreated: (state) => state.isServerBackupIsCreated,
    isRestoredBackup: (state) => state.isRestoredBackup
  },
  mutations: {
    setInitialState(state) {
      state.isServerBackupIsCreated = false
      state.isRestoredBackup = false
      state.restoreBackupErrors = []
    },
    setServerBackupIsCreated(state) {
      state.isServerBackupIsCreated = true
    },
    setServerBackupIsRestored(state) {
      state.isRestoredBackup = true
    },
    setServerBackupRestoreErrors(state, payload) {
      state.restoreBackupErrors = payload
    }
  },
  actions: {
    async createBackup({commit}, serverId) {
      commit('setInitialState')

      const response = await fetchServerCreateBackup(serverId)

      if (response && response.status === 201) {
        await commit('setServerBackupIsCreated')
      }
    },
    async restoreBackup({commit}, data) {
      commit('setInitialState')

      const response = await fetchServerBackupRestore(data)

      if (response.status === 204) {
          await commit('setServerBackupIsRestored')
      } else if (response) {
          await commit('setServerBackupRestoreErrors', response.data)
      }
    },
    async deleteBackup({commit}, data) {
      await fetchServerBackupDelete(data)
    }
  },
}
