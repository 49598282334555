import axios from 'axios'
import { getToken, unsetToken, unsetCustomer } from '@/configs/axios/token'
import { createToaster } from '@meforma/vue-toaster'
import store from '@/store'
import router from '@/router'

/**
 * @returns {axiosInstance}
 */
export const getInstance = () => {
    const toaster = createToaster()

    const axiosInstance = axios.create({
        headers: {
            'Accept': 'application/json',
        },
        'default': {
            withCredentials: true
        }
    })

    // Cancel Token Request Interceptor
    axiosInstance.interceptors.request.use( config => {
        let source = axios.CancelToken.source()

        config.cancelToken = source.token

        store.commit('add_cancel_token', source)

        return config
    },  error => {
        return Promise.reject(error)
    })

    axiosInstance.interceptors.response.use((r) => r, async ({response}) => {
        if (response && response.status === 401) {
            if (response.data.error === 'unauthorized.second-factor-required') {
                router.push('/2fa-verify');
            } else {
                // clear customer data in local storage and redirect to login page
                unsetToken()
            }
        }

        if (response && response.status === 403 && response.error === 'unauthorized.account-suspended') {
            unsetCustomer()
        }

        if (response && response.status >= 500) {
            toaster.error('We couldn\'t process your request at the moment. Please try again later')
        }

        return response
    })

    return axiosInstance
}


export const getInstanceWithToken = () => {
    const token = getToken()

    return getInstance({
        headers: token ? { Authorization: `Bearer ${token}`} : {}
    })
}

export const CsrfInstance = axios.create({
    headers: {
        'Accept': 'application/json',
    }
})
