import { createApp, reactive } from 'vue'
import axios from 'axios'
import VueCookies from 'vue3-cookies'
import VueApexCharts from 'vue3-apexcharts'
import VueAxios from 'vue-axios'
import moment from 'moment'
import Toaster from '@meforma/vue-toaster'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import App from './App.vue'
import store from '@/store'
import router from '@/router'
import i18n from './i18n'
import VueMatomo from 'vue-matomo'
import '@/main.less'
import 'moment/dist/locale/de'
import { useDark } from '@vueuse/core'

const app = createApp(App)

export const featureFlags = {
  // showAdvancedOptions: label === "CLOUD2DAY",
  // Other flags
};

async function initApp() {
  const label = import.meta.env.VITE_LABEL;

  const assets = reactive({ logo: null, authLogo: null });

  if (label === "VPS2DAY") {
    assets.logo = (await import('@/assets/images/vps2day/logo-white.svg')).default;
    assets.authLogo = (await import('@/assets/images/vps2day/logo-blue.svg')).default;
    // add other assets

  } else if (label === "CLOUD2DAY") {
    assets.logo = (await import('@/assets/images/cloud2day/logo.svg')).default;
  }

  // Providing the assets object for child components to inject
  app.provide("assets", assets);

  app.config.globalProperties.$filters = {
    timeAgo(date) {
      return moment(date).locale(i18n.global.locale.value).fromNow()
    },

    dateTime(date, format) {
      return moment(date).locale(i18n.global.locale.value).format(format)
    },

    diffDay(start, end) {
      return moment(start).locale(i18n.global.locale.value).diff(moment(end), 'days')
    }
  }

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "vps2day.com", /^\//],
      }),
    ],
    environment: import.meta.env.VITE_SENTRY_ENVIROMENT,
    tracesSampleRate: import.meta.env.VITE_SENTRY_RATE,
  })

  const isDark = useDark()
  isDark.value = localStorage.getItem('darkMode') === 'true'
  app.provide('isDark', isDark)

  app
    .use(Toaster)
    .use(VueAxios, axios)
    .use(VueCookies)
    .use(VueApexCharts)
    .use(store)
    .use(router)
    .use(i18n)
    .use(moment)
    .use(VueMatomo, {
      // Config
      router: router,
      enableHeartBeatTimer: true,
      host: import.meta.env.VITE_MATOMO_SERVER,
      siteId: import.meta.env.VITE_MATOMO_SITE_ID,
      disableCookies: import.meta.env.VITE_MATOMO_DISABLE_COOKIES,
      debug: import.meta.env.VITE_MATOMO_LOG_DEBUG_INFOS,
    })
    .component(VueQrcode.name, VueQrcode)
    .mount('#app')
}

initApp();
