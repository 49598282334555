import { fetchAffiliates } from '@/services/payments/affiliate'

const initialState = {
  affiliates: {},
}

export default {
  state: initialState,
  getters: {
    affiliates: (state) => state.affiliates,
  },
  mutations: {
    setAffiliates(state, payload) {
      state.affiliates = payload
    }
  },
  actions: {
    async getAffiliates({commit}) {
      await commit('setAffiliates', [])

      const response = await fetchAffiliates()

      if (response && response.status === 200) {
        await commit('setAffiliates', response.data)
      }
    }
  },
}
